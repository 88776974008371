/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Order } from '@gemini-vsf/api-client';
import { useCart as useCartComposable, useProduct } from '@gemini-vsf/composables';
import { computed, useContext } from '@nuxtjs/composition-api';
import { useUiState, useUiNotification, useUser, useGtm, useTranslation, useApi } from '~/composables';
import { UseCart } from '~/types/types';
import { isClient } from '~/helpers/check-environment';
import productVariantQuery from '~/helpers/product/productVariantQuery';
import { addNote, parseNotes, getNote } from './_helpers';

const useCart = (): UseCart => {
  const ctx = useContext();
  const {
    app: {
      $vsf: {
        $gemini: {
          config: { state: vsfIntegrationState },
        },
      },
    },
  } = ctx;
  const { $gt } = useTranslation('translations');
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    load: loadCart,
    cart,
    addItem: addItemComposable,
    bulkAddItems: bulkAddItemsComposable,
    addNoteOnCart: addNoteOnCartComposable,
    applyCoupon: applyCouponComposable,
    applyGiftCard: applyGiftCardComposable,
    removeCoupon: removeCouponComposable,
    removeGiftCard: removeGiftCardComposable,
    removeItem: removeItemComposable,
    updateItemQty: updateItemQtyComposable,
    // clear: clearCart,
    isInCart,
    setCart,
    error,
  } = useCartComposable();
  const { toggleCartSidebar } = useUiState();
  const { send: sendNotification } = useUiNotification();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { search: getReorderProductDetails, products } = useProduct('reorder');
  const { query } = useApi();

  const { user, isAuthenticated } = useUser();
  const { addToCartPush } = useGtm();

  const cartTotalItems = computed(() => (cart?.value && cart.value?.total_quantity) || null);
  const cartItems = computed(() => (cart?.value && cart.value?.items.filter(Boolean)) || []);
  // console.log("cartItems", cartItems.value);
  const cartNotes = computed(() => (cart?.value?.notes && parseNotes(cart.value.notes)) || {});
  const cartId = computed(() => cart?.value?.id);

  const handleEvent = (action = 'load') => {
    const skipActions = [
      'clear',
      'load',
      'removeItem',
      'bulkAddItems',
      'addNoteOnCart',
      'removeNoteFromCartItem',
      'updateItemQty',
      'setItemQuantity',
      'reorder',
    ];

    const errorMessages = {
      addItem: $gt('Error adding item to cart.'),
      removeItem: $gt('Error removing item from cart.'),
      updateItemQty: $gt('Error updating item quantity.'),
      load: $gt('Error loading cart.'),
      clear: $gt('Error clearing cart.'),
      applyCoupon: $gt('Error applying coupon.'),
      removeCoupon: $gt('Error removing coupon.'),
    };

    const errorType = Object.keys(error.value).find((key) => error.value[key]);

    if (skipActions.includes(action) && !errorType) return;

    const message = errorType ? errorMessages[errorType] : $gt('Operation successful.');
    const notificationType = errorType ? 'danger' : 'success';
    const icon = errorType ? 'error' : 'check';

    sendNotification({
      id: Symbol(errorType ? 'cart_error' : 'cart_success'),
      message: error.value.addItem?.message?.includes('Missing required fields')
        ? $gt('Before you can add to cart, all attributes must be selected.')
        : message,
      type: notificationType,
      icon,
      persist: !!errorType,
      title: errorType ? $gt('Error') : $gt('Success'),
    });
  };

  const removeNoteFromCartItem = async (merchantSku: string) => {
    try {
      const cartNotesCopy = { ...cartNotes.value };
      delete cartNotesCopy.items?.[merchantSku];
      if (cartNotesCopy.items && Object.keys(cartNotesCopy.items).length === 0) delete cartNotesCopy.items;
      await addNoteOnCartComposable({
        note: JSON.stringify(cartNotesCopy),
        customQuery: { setNotesToCart: 'setNotesToCartCustom' },
      });
    } catch (error) {
      console.error('Could not remove cart note', error);
    }
    handleEvent('removeNoteFromCartItem');
  };

  const removeItem = async ({ product }) => {
    await removeItemComposable({
      product,
      customQuery: {
        removeItemFromCart: 'removeItemFromCartCustom',
      },
    });
    await removeNoteFromCartItem(product?.product?.merchant_sku);
    handleEvent('removeItem');
  };

  const customerAdditionalInfo = computed(() => JSON.parse(user.value?.additional_info || '{}'));
  const isA4 = computed(() => customerAdditionalInfo.value?.codiceAgente === 'A     4');
  const isA50 = computed(() => user?.value?.segments?.includes('grn:customer:customer_group::65b3c2ddc1b84cf9cd4e64ac'));

  const fetchVariant = async (uid, configurations) => {
    if (!uid || !configurations?.length) {
      return null;
    }
    const searchQuery = {
      filter: {
        uid,
      },
      configurations,
    };
    const { data } = await query(productVariantQuery, searchQuery);
    return data?.['productDetail']?.configurable_product_options_selection?.variant;
  };

  const checkBulkyProducts = async () => {
    if (isClient && (isA4.value || isA50.value)) {
      await Promise.all(
        cartItems.value?.map(async (item) => {
          let bulkyProduct = false;
          // eslint-disable-next-line no-prototype-builtins
          if (item?.product?.hasOwnProperty('nontrasferibile')) {
            bulkyProduct = item.product['nontrasferibile'];
          } else {
            const variant = await fetchVariant(
              item?.product?.uid,
              item?.configurable_options?.map((p) => p?.configurable_product_option_value_uid)?.filter(Boolean)
            );
            bulkyProduct = variant?.nontrasferibile;
          }
          if (bulkyProduct) {
            await removeItem({ product: item });
            sendNotification({
              id: Symbol('cart_error'),
              message: `Il seguente prodotto è stato rimosso dal carrello in quanto ingombrante e non spedibile, richiedi il preventivo in pagina prodotto: ${item?.product?.name}`,
              type: 'danger',
              icon: 'error',
              persist: true,
              title: $gt('Error'),
            });
          }
        })
      );
    }
  };

  const load = async () => {
    // console.log("loadCart")
    await loadCart({
      customQuery: isAuthenticated.value
        ? {
            customerCart: 'customerCartCustom',
          }
        : {
            cart: 'cartCustom',
          },
    });
    handleEvent('load');
    // console.log("loadCart end")
  };

  const getNontrasferibileAttribute = async (item, configurations) => {
    const variant = item?.configurable_product_options_selection?.variant;
    // eslint-disable-next-line no-prototype-builtins
    if (variant?.hasOwnProperty('nontrasferibile')) return variant.nontrasferibile;
    const fetchedVariant = await fetchVariant(item?.uid, configurations);
    return fetchedVariant?.nontrasferibile;
  };

  const addItem = async ({ item, quantity, configurations }: { item: any; quantity: any; configurations?: any }, openSidebar = true) => {
    if (isClient && (isA4.value || isA50.value)) {
      const nontrasferibileAttribute = await getNontrasferibileAttribute(item, configurations);
      if (nontrasferibileAttribute) {
        sendNotification({
          id: Symbol('cart_error'),
          message: `Impossibile aggiungere il prodotto a carrello in quanto ingombrante e non spedibile, richiedi il preventivo in pagina prodotto`,
          type: 'danger',
          icon: 'error',
          persist: true,
          title: $gt('Error'),
        });
        return;
      }
    }
    try {
      await addItemComposable({
        product: item,
        quantity,
        customQuery: {
          addConfigurableProductsToCart: 'addConfigurableProductsToCartCustom',
        },
      });
      addToCartPush(item);
      if (cart?.value?.items?.length > 0 && openSidebar) toggleCartSidebar();
    } catch (error) {
      console.error('addItem ~ error:', error);
    }
    handleEvent('addItem');
  };

  const bulkAddItems = async (products: any[], openSidebar = true) => {
    try {
      // TODO: check bulky products
      await bulkAddItemsComposable({
        products,
        customQuery: {
          addProductsToCart: 'addProductsToCartCustom',
        },
      });
      handleEvent('bulkAddItems');
      if (cart?.value?.items?.length > 0 && openSidebar) toggleCartSidebar();
    } catch (error) {
      console.error('bulkAddItems ~ error:', error);
    }
  };

  const addNoteOnCart = async (noteKey: string, note: string) => {
    try {
      const newNote = addNote(noteKey, note, cartNotes.value);
      await addNoteOnCartComposable({
        note: newNote,
        customQuery: { setNotesToCart: 'setNotesToCartCustom' },
      });
      await load();
    } catch (error) {
      console.error('addNoteOnCart ~ error:', error);
    }
    handleEvent('addNoteOnCart');
  };

  const applyCoupon = async (couponCode: string) => {
    await applyCouponComposable({
      couponCode,
      customQuery: {
        applyCouponToCart: 'applyCouponToCartCustom',
      },
    });
  };

  const applyGiftCard = async (giftCardCode: string) => {
    await applyGiftCardComposable({
      giftCardCode,
      // customQuery: {
      //   applyGiftCardToCart: 'applyGiftCardToCartCustom',
      // },
    });
  };

  const removeCoupon = async () => {
    await removeCouponComposable({
      // @ts-ignore
      currentCart: cartId.value,
      customQuery: {
        removeCouponFromCart: 'removeCouponFromCartCustom',
      },
    });
  };

  const removeGiftCard = async (giftCardCode: string) => {
    await removeGiftCardComposable({
      giftCardCode,
      // customQuery: { removeGiftCardFromCart: 'removeGiftCardFromCartCustom' },
    });
  };

  const updateItemQty = async ({ product }, quantity: number) => {
    try {
      await updateItemQtyComposable({
        product,
        quantity,
        customQuery: {
          updateCartItems: 'updateCartItemsCustom',
        },
      });
    } catch (error) {
      console.error('updateItemQty ~ error:', error);
    }
    handleEvent('updateItemQty');
  };

  const setItemQuantity = async ({ product, quantity }) => {
    try {
      await updateItemQtyComposable({
        product,
        quantity,
        customQuery: {
          updateCartItems: 'updateCartItemsCustom',
        },
      });
    } catch (error) {
      console.error('setItemQuantity ~ error:', error);
    }
    handleEvent('setItemQuantity');
  };

  const reorder = async (order: Order) => {
    const findProduct = (item, products) => {
      const isSimple = item.product_grn.includes('/');
      return products.items.find((product) =>
        isSimple ? product.variants.find((variant) => variant.uid === item.product_grn) : product.uid === item.product_grn
      );
    };
    // @ts-ignore
    const skus = order.items.map((item) => item.product_sku);
    await getReorderProductDetails({
      pageSize: 100,
      filter: {
        sku: {
          in: skus,
        },
      },
      customQuery: { products: 'reorderProductList' },
    });
    const addableProducts = order.items
      .filter((item) => {
        // @ts-ignore
        const product = findProduct(item, products.value);
        // @ts-ignore
        return product?.status && product?.only_x_left_in_stock > 0;
      })
      .map((item) => {
        const productStock = findProduct(item, products.value).only_x_left_in_stock;
        return {
          product: {
            // @ts-ignore
            vsf_typename: item.product_grn.includes('/') ? 'ConfigurableProduct' : 'SimpleProduct',
            // @ts-ignore
            sku: item.product_grn,
          },
          // @ts-ignore
          quantity: productStock < item.quantity_ordered ? productStock : item.quantity_ordered,
        };
      });

    let failedAdds = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const addableProduct of addableProducts) {
      // @ts-ignore
      // eslint-disable-next-line no-await-in-loop
      await addItem(
        {
          item: addableProduct.product,
          quantity: addableProduct.quantity,
          // TODO: add configurations to check bulky products
        },
        false
      );
      if (error?.value?.addItem?.message) {
        const productName = products.value.items.find((product) =>
          addableProduct.product.vsf_typename === 'ConfigurableProduct'
            ? // @ts-ignore
              product.variants.find((variant) => variant.uid === addableProduct.product.sku)
            : product.uid === addableProduct.product.sku
        )?.name;
        failedAdds.push(productName || addableProduct.product.sku);
      }
    }
    if (addableProducts.length < skus.length) {
      const productsNotFound = order.items
        .filter((item) => {
          // @ts-ignore
          return !findProduct(item, products.value);
          // @ts-ignore
        })
        .map((p) => p['product_sku']);
      failedAdds = [...new Set([...failedAdds, ...productsNotFound])];
    }
    if (failedAdds.length > 0) {
      sendNotification({
        id: Symbol('reorder_error'),
        message: `Non è stato possibile aggiungere al carrello i seguenti articoli: ${failedAdds.join(', ')}`,
        type: 'danger',
        icon: 'error',
        persist: true,
        title: 'Error',
      });
    }
    if (cart?.value?.items?.length > 0) toggleCartSidebar();
  };

  const removeCart = async () => {
    vsfIntegrationState.setCartId();
    setCart(null);
    await loadCart();
  };

  const emptyCart = async () => {
    const callbacks = [];
    const items = cartItems.value;
    items.map((item) => {
      callbacks.push(removeItem({ product: item }));
      return item;
    });

    await Promise.all(callbacks);
    // console.log("Loading cart after emptying");
    await load();
    // console.log("Cart emptied");
  };

  return {
    ...useCartComposable(),
    load,
    cart,
    addItem,
    bulkAddItems,
    addNoteOnCart,
    applyCoupon,
    applyGiftCard,
    removeCoupon,
    removeGiftCard,
    removeItem,
    updateItemQty,
    isInCart,
    cartTotalItems,
    cartItems,
    error,
    reorder,
    setItemQuantity,
    cartNotes,
    getNote,
    isAuthenticated,
    removeCart,
    emptyCart,
    cartId,
    checkBulkyProducts,
  };
};

export default useCart;
